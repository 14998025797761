.vertical-center {  
    display: flex;
    align-items: center;
  }

  @media (min-width: 576px) {
    .border-sm-top {
      border-top: 1px solid #e3e7eb !important;
    }
    .border-sm-right {
      border-right: 1px solid #e3e7eb !important;
    }
    .border-sm-bottom {
      border-bottom: 1px solid #e3e7eb !important;
    }
    .border-sm-left {
      border-left: 1px solid #e3e7eb !important;
    }
    .border-sm-top-0 {
      border-top: 0 !important;
    }
    .border-sm-right-0 {
      border-right: 0 !important;
    }
    .border-sm-bottom-0 {
      border-bottom: 0 !important;
    }
    .border-sm-left-0 {
      border-left: 0 !important;
    }
    .border-sm-x {
      border-left: 1px solid #e3e7eb !important;
      border-right: 1px solid #e3e7eb !important;
    }
    .border-sm-y {
      border-top: 1px solid #e3e7eb !important;
      border-bottom: 1px solid #e3e7eb !important;
    }
    .border-sm {
      border-top: 1px solid #e3e7eb !important;
      border-bottom: 1px solid #e3e7eb !important;
      border-left: 1px solid #e3e7eb !important;
      border-right: 1px solid #e3e7eb !important;
    }
    .border-sm-0 {
      border-top: 0 !important;
      border-bottom: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;
    }
  }
  
  @media (min-width: 768px) {
    .border-md-top {
      border-top: 1px solid #e3e7eb !important;
    }
    .border-md-right {
      border-right: 1px solid #e3e7eb !important;
    }
    .border-md-bottom {
      border-bottom: 1px solid #e3e7eb !important;
    }
    .border-md-left {
      border-left: 1px solid #e3e7eb !important;
    }
    .border-md-top-0 {
      border-top: 0 !important;
    }
    .border-md-right-0 {
      border-right: 0 !important;
    }
    .border-md-bottom-0 {
      border-bottom: 0 !important;
    }
    .border-md-left-0 {
      border-left: 0 !important;
    }
    .border-md-x {
      border-left: 1px solid #e3e7eb !important;
      border-right: 1px solid #e3e7eb !important;
    }
    .border-md-y {
      border-top: 1px solid #e3e7eb !important;
      border-bottom: 1px solid #e3e7eb !important;
    }
    .border-md {
      border-top: 1px solid #e3e7eb !important;
      border-bottom: 1px solid #e3e7eb !important;
      border-left: 1px solid #e3e7eb !important;
      border-right: 1px solid #e3e7eb !important;
    }
    .border-md-0 {
      border-top: 0 !important;
      border-bottom: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;
    }
  }
  
  @media (min-width: 992px) {
    .border-lg-top {
      border-top: 1px solid #e3e7eb !important;
    }
    .border-lg-right {
      border-right: 1px solid #e3e7eb !important;
    }
    .border-lg-bottom {
      border-bottom: 1px solid #e3e7eb !important;
    }
    .border-lg-left {
      border-left: 1px solid #e3e7eb !important;
    }
    .border-lg-top-0 {
      border-top: 0 !important;
    }
    .border-lg-right-0 {
      border-right: 0 !important;
    }
    .border-lg-bottom-0 {
      border-bottom: 0 !important;
    }
    .border-lg-left-0 {
      border-left: 0 !important;
    }
    .border-lg-x {
      border-left: 1px solid #e3e7eb !important;
      border-right: 1px solid #e3e7eb !important;
    }
    .border-lg-y {
      border-top: 1px solid #e3e7eb !important;
      border-bottom: 1px solid #e3e7eb !important;
    }
    .border-lg {
      border-top: 1px solid #e3e7eb !important;
      border-bottom: 1px solid #e3e7eb !important;
      border-left: 1px solid #e3e7eb !important;
      border-right: 1px solid #e3e7eb !important;
    }
    .border-lg-0 {
      border-top: 0 !important;
      border-bottom: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;
    }
  }
  
  @media (min-width: 1200px) {
    .border-xl-top {
      border-top: 1px solid #e3e7eb !important;
    }
    .border-xl-right {
      border-right: 1px solid #e3e7eb !important;
    }
    .border-xl-bottom {
      border-bottom: 1px solid #e3e7eb !important;
    }
    .border-xl-left {
      border-left: 1px solid #e3e7eb !important;
    }
    .border-xl-top-0 {
      border-top: 0 !important;
    }
    .border-xl-right-0 {
      border-right: 0 !important;
    }
    .border-xl-bottom-0 {
      border-bottom: 0 !important;
    }
    .border-xl-left-0 {
      border-left: 0 !important;
    }
    .border-xl-x {
      border-left: 1px solid #e3e7eb !important;
      border-right: 1px solid #e3e7eb !important;
    }
    .border-xl-y {
      border-top: 1px solid #e3e7eb !important;
      border-bottom: 1px solid #e3e7eb !important;
    }
    .border-xl {
      border-top: 1px solid #e3e7eb !important;
      border-bottom: 1px solid #e3e7eb !important;
      border-left: 1px solid #e3e7eb !important;
      border-right: 1px solid #e3e7eb !important;
    }
    .border-xl-0 {
      border-top: 0 !important;
      border-bottom: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;
    }
  }