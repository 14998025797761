header.hero-image {
  /* background-image: url("../../asset/images/hero/hero_000.jpg"); */

  /* Set a specific height */
  width: 100vw;
  height: 100vh; 
  padding-top: 85vh;
  padding-bottom: 15vh;

  /* Position and center the image to scale nicely on all screens */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;

  color: #0a5f93;
}

/* Place text in the middle of the image */
header.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #0a5f93;
}


/* @media only screen and (min-width: 576px) {
  header.hero-image {
    background-image: url("../../asset/images/hero_576.png");
  }
}

@media only screen and (min-width: 768px) {
  header.hero-image {
    background-image: url("../../asset/images/hero_768.png");
  }
}

@media only screen and (min-width: 992px) {
  header.hero-image {
    background-image: url("../../asset/images/hero_000.png");
  }
} */


.hero-image {
  padding-top: 70px;
}

