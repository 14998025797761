section {
  margin: 40px 15px;
  background-color: #fff;
}

section h2 {
  color: #4d4d4d;
  margin: 0;
  line-height: 1em;
  text-transform: capitalize;
  word-wrap: break-word;
}

section hr {
  display: block;
  margin: 30px auto;
  width: 80px;
  height: 3px;
  background-color: #df733c;
  border: none;
}

.btn-wechat {
  color: #61c527;
}

.btn-facebook {
  color: #4267b2;
}

.btn-twitter {
  color: #1da1f2;
}

.btn-instagram {
  color: #3f729b
}

