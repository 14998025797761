section.features .section-heading {
  margin-bottom: 100px;
}
section.features .section-heading h2 {
  margin-top: 0;
}
section.features .section-heading p {
  margin-bottom: 0;
}

.main-content {
  padding-top: 66px; /* Space for fixed navbar */
  margin-top: 15px;
  margin-bottom: 15px;
}

.image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 25.00%;
  cursor: zoom-in;
  margin: 0px 0;
}

.image-container.open {
  cursor: zoom-out;
}

.image-container .shade {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  background: rgba(0, 0, 0, 0.9);
}

.image-container.open .shade {
  pointer-events: auto;
  opacity: 1;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 64px;
  height: 64px;
}

.image-container.open img {
  position: fixed;
  width: auto;
  height: auto;
  max-width: 100%;
  margin: auto;
}

.service-card {
  height: "520px";
  cursor: "pointer";
}

.clickable:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
  background-color: #92C323;
  color: white;
}

.player-wrapper {
  width: auto; 
  height: auto; 
}
.react-player {
  padding-top: 56.25%; 
  position: relative; 
}

.react-player > div {
  position: absolute; 
}