section.faqs .section-heading {
  margin-bottom: 100px;
}
section.faqs .section-heading h2 {
  margin-top: 0;
}
section.faqs .section-heading p {
  margin-bottom: 0;
}

.accordion .card-header:hover {
  cursor: pointer;
}

.accordion .card-header:after {
  font-family: 'Font Awesome 5 Free';  
  font-weight: 900;
  content: "\f068";
  float: right; 
}
.accordion .card-header.collapsed:after {
  font-family: 'Font Awesome 5 Free';  
  font-weight: 900;
  content: "\f067"; 
  float: right; 
}